<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="../../../../assets/img/logo/CRM-Redefined-e1677831372518-removebg-preview.png" alt="Logo"></a>
    </div>
    
    <div class="main-nav nav-bar-three">
        <div class="container-fluid">
            <nav class="container-max-2 navbar navbar-expand-sm  navbar-light ">
                <a class="navbar-brand h-25" routerLink=""><img src="../../../../assets/img/logo/CRM-Redefined-e1677831372518-removebg-preview.png" style="width: 25%; " alt="Logo" ></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto flex ">
                        <!-- <li class="nav-item">
                            <a class="nav-link">Home </a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">SEO Agency</a></li>

                                <li class="nav-item"><a routerLink="/ai-startup" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">AI Startup</a></li>

                                <li class="nav-item"><a routerLink="/machine-learning" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Machine Learning</a></li>
                            </ul>
                        </li> -->

                        <!-- <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Product</a></li> -->

                        <li class="nav-item">
                            <a routerLink="/features" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Features</a>

                            <!-- <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/lead" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Lead Mangement</a></li>

                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Lead Nurturing</a></li>

                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Campaign Management</a></li>

                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">User Management</a></li>

                                <li class="nav-item">
                                    <a class="nav-link"> Publisher panel</a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> Strategic Remarketing </a></li>

                                        <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> Whatsaap Business API</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Advanced Form Builder</a></li>

                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Application Manager</a></li>

                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Payment Manager</a></li>

                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Student Enrolment Portal</a></li>

                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Query Management System</a></li>

                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Result Declaration</a></li>
                            </ul>
                        </li> -->

                        <li class="nav-item">
                            <a class="nav-link">Resources</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Video</a></li>

                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Academey</a></li>
                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Price Plan</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link">Solution </a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">EdTech</a></li>
                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coaching & Training Institutes</a></li>
                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">K-12 Schools</a></li>
                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Preschool & playschools</a></li>
                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Online Degree programmes</a></li>
                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Higher Education</a></li>
                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Study Abroad Consultants</a></li>
                                <!-- <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Management team</a></li>
                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Admission Teams</a></li>
                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sales Teams</a></li>
                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Marketing Teams</a></li>
                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Finance Teams</a></li>
                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">IT Teams</a></li> -->
                                <!-- <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">360 Communication</a></li> -->


                                <!-- <li class="nav-item"><a routerLink="/services-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services Details</a></li> -->
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link">Company</a>

                            <ul class="dropdown-menu">
                                <!-- <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Our Partners</a></li> -->

                                <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>
                                <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About us</a></li>
                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>
                                <li class="nav-item"><a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms Conditions</a></li>
                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">In The News</a></li>
                                

                                <!-- <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Decoding Zabardast</a></li> -->

                                <li class="nav-item">
                                    <a class="nav-link">Success Stories </a>

                                    <!-- <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case study</a></li>

                                        <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case study Details</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error</a></li>

                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign In</a></li>

                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>

                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a></li>

                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>

                                <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li> -->
                            </ul>
                        </li>

                        <li class="nav-item"><a href="https://apps.eduwego.in/login" target="_blank" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><b>Login</b></a></li>
                        <li class="nav-item"><a href="https://apps.eduwego.in/signup" target="_blank" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><b>SignUp</b></a></li>
                         <!-- <button type="button" class="btn btn-info  btn-xs" ><li class="nav-item"><a routerLink="#" class="nav-link  " routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Login</a></li></button>
                        <button type="button" class="btn btn-warning  btn-sm"><li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">SignUp</a></li></button> -->
                    </ul>

                    <!-- <div class="side-nav d-in-line align-items-center">
                        <div class="side-item">
                            <div class="search-box">
                                <i class="flaticon-loupe"></i>
                            </div>
                        </div>
                        <div class="side-item">
                            <div class="user-btn">
                                <a routerLink="/"> </a>
                            </div>
                        </div>
                        <div class="side-item">
                            <div class="user-btn">
                                <a routerLink="/"><i class="flaticon-contact"></i></a>
                            </div>
                        </div>
                        <div class="side-item">
                            <div class="user-btn">
                                <a routerLink="/"><i class="flaticon-contact"></i></a>
                            </div>
                        </div>
                        <div class="side-item">
                            <div class="nav-add-btn">
                                <a routerLink="/contact" class="nav-menu-btn">Contact Us <i class='bx bx-plus'></i></a>
                            </div>
                        </div>
                    </div> -->
                </div>
            </nav>
        </div>
    </div>

    <div class="side-nav-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="circle-inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>
            
            <div class="container">
                <div class="side-nav-inner">
                    <div class="side-nav side-nav-two justify-content-center  align-items-center">
                        <div class="side-item">
                            <div class="search-box-2">
                                <form class="search-form">
                                    <input class="form-control" name="search" placeholder="Search Your Result" type="text">
                                    <button class="search-btn" type="submit"><i class="bx bx-search"></i></button>
                                </form>
                            </div>
                        </div>
                        <div class="side-item">
                            <div class="nav-add-btn">
                                <a routerLink="/contact" class="nav-menu-btn border-radius">Get started <i class='bx bx-plus'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>