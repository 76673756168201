<app-navbar-style-three></app-navbar-style-three>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Features</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Features</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-widget-area pt-50 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Services</span>
            <h2 class="h2-color">We’re Flexible to <b>Provide You Best</b></h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details"><img src="../../../../assets/img/service/lead generation.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">Lead Generation
                    </a></h3>
                    <!-- <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p> -->
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details"><img src="../../../../assets/img/service/live application.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">Live Application Form</a></h3>
                    <!-- <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p> -->
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details"><img src="../../../../assets/img/service/fee payments.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">Fee Payments</a></h3>
                    <!-- <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p> -->
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details"><img src="../../../../assets/img/service/refferals ans associate.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">Referrals & Associate
                    </a></h3>
                    <!-- <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p> -->
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details"><img src="../../../../assets/img/service/assignments.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">Assignments</a></h3>
                    <!-- <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p> -->
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details"><img src="../../../../assets/img/service/batch and results.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">Batch & Results
                    </a></h3>
                    <!-- <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p> -->
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="row pt-45">
                <div class="col-lg-4 col-md-6">
                    <div class="services-card">
                        <a routerLink="/services-details"><img src="../../../../assets/img/service/attendance.png" alt="Images"></a>
                        <h3><a routerLink="/services-details">Attendance</a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p> -->
                        <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                    </div>
                </div>
    
                <div class="col-lg-4 col-md-6">
                    <div class="services-card">
                        <a routerLink="/services-details"><img src="../../../../assets/img/service/patment gateaway.png" alt="Images"></a>
                        <h3><a routerLink="/services-details">Payment Gateway
                        </a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p> -->
                        <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                    </div>
                </div>
    
                <div class="col-lg-4 col-md-6">
                    <div class="services-card">
                        <a routerLink="/services-details"><img src="../../../../assets/img/service/placements and internship.png" alt="Images"></a>
                        <h3><a routerLink="/services-details">Placements/Internships</a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p> -->
                        <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                    </div>
                </div>
    
                <div class="col-lg-4 col-md-6">
                    <div class="services-card">
                        <a routerLink="/services-details"><img src="../../../../assets/img/service/study material.png" alt="Images"></a>
                        <h3><a routerLink="/services-details">Study Materials</a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p> -->
                        <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                    </div>
                </div>
            
            <!-- <div class="col-lg-12 col-md-12">
                <div class="pagination-area text-center">
                    <a routerLink="/services" class="prev page-numbers"><i class="bx bx-chevron-left"></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/services" class="page-numbers">2</a>
                    <a routerLink="/services" class="page-numbers">3</a>
                    <a routerLink="/services" class="page-numbers">4</a>
                    <a routerLink="/services" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                </div>
            </div> -->
        </div>
    </div>
</div>

<app-ed-tech-banner></app-ed-tech-banner>

<app-footer-style-two></app-footer-style-two>