
<app-navbar-style-three></app-navbar-style-three>
<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center aat">
            <h3>Pricing Plan</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Pricing Plan</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="pricing-area pt-0 pb-0">
    <div class="container-fluid">
        <div class="section-title text-center">
            <span class="sp-after">Pricing Plan</span>
            <h2 class="h2-color">Price & Plans <b>Packages</b></h2>
        </div>

        <div class="price-width">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg1">
                            <i class="flaticon-banner pricing-icon color-1"></i>
                            <h3 class="color-1">Prime
                            </h3>
                            <div class="price-rate">
                                <h2 class="color-1">₹ 25,000 </h2>
                                <span class="color-1">Per Academic Year</span>
                            </div>
                            <ul>
                                <li> Landing Page
                                </li>
                                <li><i class='bx bx-check'></i> Live Application Form
                                </li>
                                <li><i class='bx bx-check'></i> Manage Leads upto <b>500</b>
                                </li>
                                <li><i class='bx bx-check'></i>  Students up to <b>500</b>
                                </li>
                                <li><i class='bx bx-check'></i>  Applications up to <b>500</b>
                                </li>
                                <li><i class='bx bx-check'></i>SMS</li>
                                <li><i class='bx bx-check'></i>Transaction Email</li>
                                <li><i class='bx bx-check'></i> <b>Web version</b> Business Whatsapp
                                </li>
                                <!-- <li><i class='bx bx-check'></i>IVR</li> -->
                                <!-- <li><i class='bx bx-check'></i>Website</li> -->
                                <li><i class='bx bx-check'></i>Payment Gateway</li>
                                <li><i class='bx bx-check'></i> Employee Portal
                                </li>
                                <!-- <li><i class='bx bx-check'></i>Data Migrates</li> -->
                                <!-- <li><i class='bx bx-check'></i>Personal Sender Id</li> -->
                                <!-- <li><i class='bx bx-check'></i>SMS</li> -->
                                <li><i class='bx bx-check'></i> <b>10</b> No. of Users
                                </li>
                                <!-- <li><i class='bx bx-check'></i>Android mobile app</li> -->
                                <li><i class='bx bx-check'></i>Facebook Integration</li>
                                <li><del>Student Portal</del></li>
                                <li><del>IVR</del></li>
                                <li><del>Website</del></li>
                                <li><del>Data Migrates</del></li>
                                <li><del>Personal Sender Id</del></li>
                                <li><del>Android Mobile App</del></li>
                                
                                <li><del>Multi Campus Management</del></li>
                            </ul>
                            <a routerLink="/pricing" class="purchase-btn button-bg1">Subscribe NOW</a>
                            <b>
                                <p>₹2083 per month</p>
                            </b>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg2">
                            <i class="flaticon-mortgage-loan pricing-icon color-2"></i>
                            <h3 class="color-2">Prime Plus</h3>
                            <div class="price-rate">
                                <h2 class="color-2">₹60,000</h2>
                                <span class="color-2">Per Academic Year</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Landing Page
                                </li>
                                <li><i class='bx bx-check'></i> Live Application Form
                                </li>
                                <li><i class='bx bx-check'></i> Manage Leads upto <b>1000</b>
                                </li>
                                <li><i class='bx bx-check'></i>  Students up to <b>1000</b>
                                </li>
                                <li><i class='bx bx-check'></i>  Applications up to <b>2500</b>
                                </li>
                                <li><i class='bx bx-check'></i>SMS</li>
                                <li><i class='bx bx-check'></i>Transaction Email</li>
                                <li><i class='bx bx-check'></i> <b>API version</b> Business Whatsapp
                                </li>
                                <li><i class='bx bx-check'></i>IVR</li>
                                <li><i class='bx bx-check'></i>Website</li>
                                <li><i class='bx bx-check'></i>Payment Gateway</li>
                                <li><i class='bx bx-check'></i> Employee Portal
                                </li>
                                <li><i class='bx bx-check'></i>Data Migrates</li>
                                <li><i class='bx bx-check'></i>Personal Sender Id</li>
                                <li><i class='bx bx-check'></i>SMS</li>
                                <li><i class='bx bx-check'></i> <b>20</b> No. of Users
                                </li>
                                <li><i class='bx bx-check'></i>Android mobile app</li>
                                <li><i class='bx bx-check'></i>Facebook Integration</li>
                                <li><del>Student Portal</del></li>
                                <!-- <li><i class='bx bx-check'></i>SMS</li> -->
                                <li><del>Multi Campus Management</del></li>
                            </ul>
                            <a routerLink="/pricing" class="purchase-btn button-bg2">Subscribe Now</a>
                            <b>
                                <p>₹5000 per month</p>
                            </b>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg3">
                            <i class="flaticon-processing pricing-icon color-3"></i>
                            <h3 class="color-3">Prime Pro</h3>
                            <div class="price-rate">
                                <h2 class="color-3">₹1,00,000</h2>
                                <span class="color-3">Per Academic Year</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Landing Page
                                </li>
                                <li><i class='bx bx-check'></i> Live Application Form
                                </li>
                                <li><i class='bx bx-check'></i>  Leads up to <b>Unlimited</b>
                                    
                                </li>
                                <li><i class='bx bx-check'></i>  Students up to <b>Unlimited</b>
                                    
                                </li>
                                <li><i class='bx bx-check'></i>  Applications up to <b>Unlimited</b>
                                </li>
                                <li><i class='bx bx-check'></i>SMS</li>
                                <li><i class='bx bx-check'></i>Transaction Email</li>
                                <li><i class='bx bx-check'></i> <b>API version</b> Business Whatsapp
                                </li>
                                <li><i class='bx bx-check'></i>IVR</li>
                                <li><i class='bx bx-check'></i>Website</li>
                                <li><i class='bx bx-check'></i>Payment Gateway</li>
                                <li><i class='bx bx-check'></i> Employee Portal
                                </li>
                                <li><i class='bx bx-check'></i>Data Migrates</li>
                                <li><i class='bx bx-check'></i>Personal Sender Id</li>
                                <li><i class='bx bx-check'></i>SMS</li>
                                <li><i class='bx bx-check'></i> <b>20</b> No. of Users
                                </li>
                                <li><i class='bx bx-check'></i>Android mobile app</li>
                                <li><i class='bx bx-check'></i>Facebook Integration</li>
                                <li><del>Student Portal</del></li>
                                <li><i class='bx bx-check'></i>SMS</li>
                                <li><del>Multi Campus Management</del></li>
                            </ul>
                            <a routerLink="/pricing" class="purchase-btn button-bg3">Subscribe Now</a>
                            <b>
                                <p>₹8,333.3 per month</p>
                            </b>
                        </div>
                    </div>
                </div>
            </div>
            <p id="z-taxplus" style="display: block;">Local taxes (VAT, GST, etc.) will be charged in addition to the prices mentioned.</p>
            <p>* Inclusive of archival and retention storage. <a href="/pricing">Know more</a>.</p>
        </div>
    </div>





    <div class="pricing-shape">
        <div class="shape1"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape6"><img src="assets/img/shape/shape8.png" alt="Images"></div>
    </div>
</div>

<div class="pricing-area pt-0 pb-0">
  <div class="container-fluid">
      <div class="section-title text-center">
          <!-- <span class="sp-after">Pricing Plan</span>
          <h2 class="h2-color">Price & Plans <b>Packages</b></h2> -->
          <h2> Free Forever Plan for College & Institute</h2>
          <a class="trynow" href="#">Try Now</a>
      </div>

      <div class="price-width">
          <div class="row">
              <div class="col-lg-4 col-sm-6">
                  <div class="pricing-card">
                      <div class="pricing-card-into color-bg4">
                        <h4>Forever Free Plan</h4>
                          <!-- <i class="flaticon-banner pricing-icon color-1"></i>
                          <h3 class="color-1">Prime
                          </h3>
                          <div class="price-rate">
                              <h2 class="color-1">₹ 25,000 </h2>
                              <span class="color-1">Per Academic Year</span>
                          </div> -->
                          <ul>
                            <li> No. of Users -
                                <b>1</b>

                            </li>
                              <li> Applications up to - <b>50</b>

                              </li>
                              <li> Leads up to -<b>250</b>

                              </li>
                              <li> Students up to - <b>50</b>

                              </li>
                           
                          </ul>
                          <a routerLink="#" class="purchase-btn button-bg1">Sign Up NOW</a>
                          <!-- <b>
                              <p>₹2083 per month</p>
                          </b> -->
                      </div>
                  </div>
              </div>

              <div class="col-lg-4 col-sm-6">
                  <div class="pricing-card">
                      <div class="pricing-card-into color-bg2">
                        <h4>Contact Sales</h4>
                          <!-- <i class="flaticon-mortgage-loan pricing-icon color-2"></i>
                          <h3 class="color-2">Prime Plus</h3>
                          <div class="price-rate">
                              <h2 class="color-2">₹60,000</h2>
                              <span class="color-2">Per Academic Year</span>
                          </div> -->
                          <ul>
                              <li> Get Custom plans for bulk user and enterprises
                              </li>
                      
              
                          </ul>
                          <a routerLink="#" class="purchase-btn button-bg2">Contact Us</a>
                          <!-- <b>
                              <p>₹5000 per month</p>
                          </b> -->
                      </div>
                  </div>
              </div>

              <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                  <div class="pricing-card">
                      <div class="pricing-card-into color-bg6">
                          <!-- <i class="flaticon-processing pricing-icon color-3"></i> -->
                          <!-- <h3>Flexible Pricing</h3> -->
                          <h4>Flexible Pricing</h4>
                          <!-- <div class="price-rate">
                              <h2 class="color-3">₹1,00,000</h2>
                              <span class="color-3">Per Academic Year</span>
                          </div> -->
                          <ul>
                              <li> Mix and Match different plans for different user in your organization
                              </li>
                      
                          </ul>
                          <a routerLink="#" class="purchase-btn button-bg3">Explore Now</a>
                          <!-- <b>
                              <p>₹8,333.3 per month</p>
                          </b> -->
                      </div>
                  </div>
              </div>
              <div class="section-title text-center">
                <span class="sp-after">Single User + Admin</span>
                <h2 class="h2-color">One Time Setup Fee 3999+ GST</h2>
                <!-- <h2>15-day free trial for Mail Premium plan. No credit card required.</h2>
                <a class="trynow" href="#">Try Now</a> -->
            </div>
          </div>
      </div>
  </div>






  <!-- <div class="pricing-shape">
      <div class="shape1"><img src="assets/img/shape/shape7.png" alt="Images"></div>
      <div class="shape2"><img src="assets/img/shape/shape4.png" alt="Images"></div>
      <div class="shape3"><img src="assets/img/shape/shape5.png" alt="Images"></div>
      <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
      <div class="shape5"><img src="assets/img/shape/shape4.png" alt="Images"></div>
      <div class="shape6"><img src="assets/img/shape/shape8.png" alt="Images"></div>
  </div> -->
</div>




<app-ed-tech-banner></app-ed-tech-banner>

<app-footer-style-two></app-footer-style-two>