<footer class="footer-area footer-bg2 pt-100">
    <div class="container">
        <div class="footer-midal pb-70">
            <div class="row">
                <div class="col-lg-4 col-sm-7">
                    <div class="footer-widget">
                        <div class="footer-img">
                            <img src="../../../../assets/img/logo/CRM-Redefined-e1677831372518-removebg-preview.png" style="width: 50%;" alt="Images">
                        </div>
                        <p>We have 30 years  strategy includes consistently evolving, to ensure we’re producing exceptional SEO for business.Form Leads to Students , eduwego will take care of all your leads. Manage all your leads & scale up your admissions upto 60X
                        </p>
                        <div class="footer-social-icon">
                            <ul class="social-link">
                                <li><a href="https://www.facebook.com/eduwego/" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                                <li><a href="https://twitter.com/eduwego" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                                <li><a href="https://www.instagram.com/eduwego.in/" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                                <!-- <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>  -->
                                <li><a href="https://www.youtube.com/@eduwego" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-sm-5">
                    <div class="footer-widget">
                        <h3>Products</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/lead">Lead management</a></li>
                            <li><a routerLink="/">Leads Communication</a></li>
                            <li><a routerLink="/">Leads Nurturing</a></li>
                            <li><a routerLink="/">Online Admission management
                            </a></li>
                            <li><a routerLink="/">Admission Letter Kit</a></li>
                            <li><a routerLink="/">Associates Management</a></li>
                            <li><a routerLink="/">Students management </a></li>
                            <li><a routerLink="/">Fees management</a></li>
                            <li><a routerLink="/">Vendor Management</a></li>
                            <li><a routerLink="/">Reports & Analytics
                            </a></li>
                            <li><a routerLink="/">Online Payments</a></li>
                            <li><a routerLink="/">Server</a></li>
                            <li><a routerLink="/">Data Security</a></li>
                            <!-- <li><a routerLink="/">Admission</a></li>
                            <li><a routerLink="/">Admission</a></li> -->
                        </ul>
                    </div>
                </div>
                

                <div class="col-lg-3 col-sm-7">
                    <div class="footer-widget pl-5">
                        <h3>Resources</h3>

                        <ul class="footer-list">
                            <!-- <li><a routerLink="/">Home</a></li>
                            <li><a routerLink="/about">About</a></li>
                            <li><a routerLink="/case-study">Case Studies</a></li>
                            <li><a routerLink="/blog">Our Blog</a></li>
                            <li><a routerLink="/contact">Contact</a></li> -->
                            <li><a routerLink="/">100 % Secured</a></li>
                            <li><a routerLink="/">Daily Backup </a></li>
                            <li><a routerLink="/">Indian Server</a></li>
                            <li><a routerLink="/">Optimized Server</a></li>
                            <li><a routerLink="/">Data Privacy</a></li>
                            <li><a routerLink="/">Fully Customized</a></li>
                            <!-- <li><a routerLink="/">Admission</a></li>
                            <li><a routerLink="/">Admission</a></li>
                            <li><a routerLink="/">Admission</a></li>
                            <li><a routerLink="/">Admission</a></li>
                            <li><a routerLink="/">Admission</a></li>
                            <li><a routerLink="/">Admission</a></li>
                            <li><a routerLink="/">Admission</a></li>
                            <li><a routerLink="/">Admission</a></li> -->

                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-5">
                    <div class="footer-widget">
                        <h3>Address</h3>

                        <ul class="footer-list-two">
                            <li><i class='flaticon-telephone'></i><a href="tel:+91 8210920357">+91 8210920357</a></li>
                            <li><i class='flaticon-email-1'></i><a href="mailto:hello@eduwego.in">hello@eduwego.in</a></li>
                            <li><i class='flaticon-place'></i>#5, 10th Cross , Neeladari Nagar , Electronic City Phase 1 , Bangalore , Karnataka.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copy-right-area">
            <div class="row">
                <div class="col-lg-8">
                    <div class="copy-right-text text-start">
                        <p>© Eduwego is Proudly Owned by <a href="https://www.eduwego.in/" target="_blank">Zeqoon</a></p>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="copy-right-list">
                        <ul>
                            <li><a routerLink="/privacy-policy"> Privacy Policy</a></li>
                            <li><a routerLink="/terms-conditions"> Terms & Conditions</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>